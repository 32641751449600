import styled, { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
  *{
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  html, body, #root{
    min-height: 100%;
  }

  body{
    background: #FFF;
    -webkit-font-smoothing: antialiased !important;
  }

  a {
    color: #333;
    text-decoration: none;
  }

  input{
    padding: 0 10px;
    display: inline;
    margin-left: 5px;
    margin-right: 5px;
    justify-content: space-between;
    border-radius: 5px;
    border: 1px #ccc;
    border-style: solid;
    padding: 4px 10px 4px 10px;
  }

  label{
    padding: 4px 5px 4px 5px;
  }

  body, input, button, textarea{
    color: #222;
    font-size: 14px;
    font-family: 'Barlow', sans-serif;
  }

  button{
    cursor: pointer;
  }
`;

export const Content = styled.div`
  padding: 30px;
  margin: 0 auto;
  display: block;
  justify-content: space-between;
  background-color: #e3e3e3;
  max-width: 400px;
`;

export const Espaco = styled.div`
  padding: 15px;
  clear: both;
  width: 100%;
`;

export const Button = styled.button`
  padding: 10px 30px;
  font-weight: 900;
  background: #075aaa;
  border-radius: 10px;
  color: #fff;
`;

export const ContentForm = styled.input`
  padding: 0 10px;
  min-width: 100%;
  display: inline;
  margin: 5px;
  justify-content: space-between;
  border-radius: 5px;
  border: 1px #ccc;
  border-style: solid;
  padding: 4px 10px 4px 10px;
`;

export const NotaForm = styled.label`
  color: #555;
  padding: 4px 10px 15px 10px;
  display: block;
`;

export const Duvida = styled.button`
  background: #075aaa;
  padding: 4px 10px 4px 10px;
  border-radius: 10px;
  color: #fff;
  border: 0;
  font-size: 10px;
`;

export const EspacoQ = styled.div`
  padding: 5px;
  clear: both;
  width: 100%;
`;

export const Caixa = styled.div`
  width: 100%;
  height: 70%;
  align-items: center;
  justify-content: center;
  padding: 44px 15px;
  margin-top: 50px;
  background-color: #fff;
  border-radius: 10px;
  color: #8cc541;
`;

export const CaixaBranca = styled.div`
  width: 100%;
  height: 70%;
  align-items: center;
  justify-content: center;
  padding: 44px 15px;
  margin-top: 10px;
  background-color: #fff;
  border-radius: 10px;
  color: #8cc541;
`;

export const CaixaMonitoramento = styled.div`
  width: 100%;
  height: 80px;
  align-items: center;
  justify-content: center;
  padding: 5px;
  margin: 10px 0px;
  background-color: #fff;
  border-radius: 10px;
  color: #8cc541;
`;

export const CaixaMonitoramentoInicio = styled.div`
  width: 100%;
  height: 250px;
  align-items: center;
  justify-content: center;
  padding: 44px 15px;
  background-color: #fff;
  border-radius: 10px;
  margin: 10px 0px 25px 0px;
  color: #8cc541;
`;

export const TextAceit = styled.div`
  position: relative;
  background-color: #8cc541;
  top: 44px;
  width: 110%;
  left: -15px;
  border-radius: 0px 0px 10px 10px;
  text-align: center;
  height: 25px;
  color: #fff;
  padding: 3px 0px;
`;

export const TituloTerm = styled.div`
  font-family: "Barlow";
  font-weight: 600;
  font-size: 20px;
  margin-top: 10;
  margin-bottom: 10px;
  text-align: center;
  color: #8cc541;
`;

export const ButtonProx = styled.button`
  width: 100%;
  height: 50px;
  margin: 15px 0px;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  border-width: 1px;
  border-color: #000;
  padding: 5px;
  background-color: transparent;
`;

export const TituloCard = styled.p`
  margin-left: 10px;
  color: #555;
`;

export const MedicaoCard = styled.div`
  font-size: 25px;
  font-weight: 500;
  margin-left: 10px;
  color: #555;
`;

export const DataCard = styled.p`
  margin-left: 10px;
  color: #555;
`;

export const AlertCard = styled.p`
  position: relative;
  left: 85%;
  top: -60%;
  background-color: #f00;
  width: 25px;
  height: 25px;
  text-align: center;
  border-radius: 25px;
  color: #fff;
  font-size: 20px;
  font-weight: 900;
`;

export const AlertCardOff = styled.p`
  position: relative;
  left: 85%;
  top: -60%;
  width: 25px;
  height: 25px;
  text-align: center;
  border-radius: 25px;
  color: #fff;
  font-size: 20px;
  font-weight: 900;
`;

export const AlertCorRed = styled.p`
  background-color: #f00;
  width: 15px;
  height: 80px;
  position: relative;
  left: 97%;
  top: -134%;
  border-radius: 0px 10px 10px 0px;
`;

export const AlertCorYellow = styled.p`
  background-color: #f5cb38;
  width: 15px;
  height: 80px;
  position: relative;
  left: 97%;
  top: -134%;
  border-radius: 0px 10px 10px 0px;
`;

export const AlertCorGreen = styled.p`
  background-color: #8cc541;
  width: 15px;
  height: 80px;
  position: relative;
  left: 97%;
  top: -134%;
  border-radius: 0px 10px 10px 0px;
`;

export const TituloCardPrincipalRed = styled.div`
  font-family: "Barlow";
  font-weight: 700;
  font-size: 30px;
  margin-top: 10;
  margin-bottom: 45px;
  text-align: center;
  color: #f00;
`;

export const TituloCardPrincipalYellow = styled.div`
  font-family: "Barlow";
  font-weight: 700;
  font-size: 30px;
  margin-top: 10;
  margin-bottom: 45px;
  text-align: center;
  color: #f5cb38;
`;

export const TituloCardPrincipalGreen = styled.div`
  font-family: "Barlow";
  font-weight: 700;
  font-size: 30px;
  margin-top: 10;
  margin-bottom: 45px;
  text-align: center;
  color: #8cc541;
`;

export const TextoCardPrincipal = styled.div`
  font-family: "Barlow";
  font-weight: 500;
  font-size: 15px;
  margin-top: 10;
  margin-bottom: 10px;
  text-align: center;
  color: #555;
`;

export const TextoCoachingSaudavel = styled.div`
  font-family: "Barlow";
  font-weight: 500;
  font-size: 25px;
  margin: 20px;
  color: #555;
  text-align: center;
`;

export const TituloUpdate = styled.h1`
  text-align: center;
  margin: 10px;
  color: #555;
`;

export const TextoUpdate = styled.p`
  text-align: center;
  margin: 10px;
  font-size: 15px;
  color: #555;
`;

export const ImgUpdade = styled.img`
  text-align: center;
  height: 80px;
`;

export const InputCompleto = styled.input`
  width: 100%;
`;

export const InputDadosUsuario = styled.input`
  width: 98%;
  margin: 10px 0px;
  height: 30px;
`;

export const InputCheckBoxUsuario = styled.input``;

export const LabelUpdate = styled.label`
  width: 100%;
  text-align: center;
  color: #000;
`;

export const Logo = styled.img`
  max-width: 80%;
  text-align: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
`;
