import React from "react";
import Routes from "./routes";
import GlobolStyle from "./styles/global";

function App() {
  return (
    <>
      <Routes />
      <GlobolStyle />
    </>
  );
}

export default App;
