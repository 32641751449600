/* eslint-disable class-methods-use-this */
import React, { Component } from "react";
import {
  Content,
  CaixaMonitoramento,
  CaixaMonitoramentoInicio,
  TituloCardPrincipalRed,
  TituloCardPrincipalYellow,
  TituloCardPrincipalGreen,
  TituloCard,
  MedicaoCard,
  DataCard,
  AlertCard,
  AlertCardOff,
  TextoCardPrincipal,
  TextoCoachingSaudavel,
  AlertCorRed,
  AlertCorYellow,
  AlertCorGreen,
} from "../../styles/global";

export default class Monitoramento extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(e) {
    e.preventDefault();
    this.props.history.push("");
  }

  getRiscoGeral() {
    const datauser = JSON.parse(localStorage.getItem("monitoramento"));
    if (
      datauser.circunferencia[0].risco === "2" ||
      datauser.glicose[0].risco === "2" ||
      datauser.peso[0].risco === "2" ||
      datauser.pressao[0].risco === "2" ||
      datauser.triglicerideos[0].risco === "2"
    ) {
      return (
        <CaixaMonitoramentoInicio>
          <TituloCardPrincipalRed>RISCO</TituloCardPrincipalRed>
          <TextoCardPrincipal>CUIDADO!</TextoCardPrincipal>
          <TextoCardPrincipal>
            Duas ou mais variaveis estão fora de referencia.
          </TextoCardPrincipal>
        </CaixaMonitoramentoInicio>
      );
    } else if (
      datauser.circunferencia[0].risco === "1" ||
      datauser.glicose[0].risco === "1" ||
      datauser.peso[0].risco === "1" ||
      datauser.pressao[0].risco === "1" ||
      datauser.triglicerideos[0].risco === "1"
    ) {
      return (
        <CaixaMonitoramentoInicio>
          <TituloCardPrincipalYellow>ATENÇÃO</TituloCardPrincipalYellow>
          <TextoCardPrincipal>FIQUE ATENTO!</TextoCardPrincipal>
          <TextoCardPrincipal>
            Uma variavel está fora da refêrencia.
          </TextoCardPrincipal>
        </CaixaMonitoramentoInicio>
      );
    } else {
      return (
        <CaixaMonitoramentoInicio>
          <TituloCardPrincipalGreen>SAUDÁVEL</TituloCardPrincipalGreen>
          <TextoCardPrincipal>PARABÉNS!</TextoCardPrincipal>
          <TextoCardPrincipal>
            Nenhuma variável está fora da refêrencia.
          </TextoCardPrincipal>
        </CaixaMonitoramentoInicio>
      );
    }
  }

  render() {
    const datauser = JSON.parse(localStorage.getItem("monitoramento"));
    return (
      <Content>
        <form onSubmit={this.handleSubmit.bind(this)}>
          {this.getRiscoGeral()}

          <a href="/envio?page=coaching">
            <CaixaMonitoramento>
              <TextoCoachingSaudavel>Coaching Saudável</TextoCoachingSaudavel>
            </CaixaMonitoramento>
          </a>

          <a href="/envio?page=peso">
            <CaixaMonitoramento>
              <TituloCard>Peso</TituloCard>
              <MedicaoCard>{datauser.peso[0].value} Kg</MedicaoCard>
              <DataCard>{datauser.peso[0].created_at}</DataCard>
              {datauser.peso[0].atrasado ? (
                <AlertCard>!</AlertCard>
              ) : (
                <AlertCardOff></AlertCardOff>
              )}
              {(() => {
                if (datauser.peso[0].risco === "0") {
                  return <AlertCorGreen></AlertCorGreen>;
                } else if (datauser.peso[0].risco === "1") {
                  return <AlertCorYellow></AlertCorYellow>;
                } else {
                  return <AlertCorRed></AlertCorRed>;
                }
              })()}
            </CaixaMonitoramento>
          </a>

          <a href="/envio?page=circunferencia">
            <CaixaMonitoramento>
              <TituloCard>Circ. Abdominal</TituloCard>
              <MedicaoCard>{datauser.circunferencia[0].value} cm</MedicaoCard>
              <DataCard>{datauser.circunferencia[0].created_at}</DataCard>
              {datauser.circunferencia[0].atrasado ? (
                <AlertCard>!</AlertCard>
              ) : (
                <AlertCardOff></AlertCardOff>
              )}
              {(() => {
                if (datauser.circunferencia[0].risco === "0") {
                  return <AlertCorGreen></AlertCorGreen>;
                } else if (datauser.circunferencia[0].risco === "1") {
                  return <AlertCorYellow></AlertCorYellow>;
                } else {
                  return <AlertCorRed></AlertCorRed>;
                }
              })()}
            </CaixaMonitoramento>
          </a>

          <a href="/envio?page=pressao">
            <CaixaMonitoramento>
              <TituloCard>Pressão Arterial</TituloCard>
              <MedicaoCard>{datauser.pressao[0].value}</MedicaoCard>
              <DataCard>{datauser.pressao[0].created_at}</DataCard>
              {datauser.pressao[0].atrasado ? (
                <AlertCard>!</AlertCard>
              ) : (
                <AlertCardOff></AlertCardOff>
              )}
              {(() => {
                if (datauser.pressao[0].risco === "0") {
                  return <AlertCorGreen></AlertCorGreen>;
                } else if (datauser.pressao[0].risco === "1") {
                  return <AlertCorYellow></AlertCorYellow>;
                } else {
                  return <AlertCorRed></AlertCorRed>;
                }
              })()}
            </CaixaMonitoramento>
          </a>

          <a href="/envio?page=glicose">
            <CaixaMonitoramento>
              <TituloCard>Glicose</TituloCard>
              <MedicaoCard>{datauser.glicose[0].value} mg/dL</MedicaoCard>
              <DataCard>{datauser.glicose[0].created_at}</DataCard>
              {datauser.glicose[0].atrasado ? (
                <AlertCard>!</AlertCard>
              ) : (
                <AlertCardOff></AlertCardOff>
              )}
              {(() => {
                if (datauser.glicose[0].risco === "0") {
                  return <AlertCorGreen></AlertCorGreen>;
                } else if (datauser.glicose[0].risco === "1") {
                  return <AlertCorYellow></AlertCorYellow>;
                } else {
                  return <AlertCorRed></AlertCorRed>;
                }
              })()}
            </CaixaMonitoramento>
          </a>

          <a href="/envio?page=triglicerideos">
            <CaixaMonitoramento>
              <TituloCard>Triglicerideos</TituloCard>
              <MedicaoCard>
                {datauser.triglicerideos[0].value} mg/dL
              </MedicaoCard>
              <DataCard>{datauser.triglicerideos[0].created_at}</DataCard>
              {datauser.triglicerideos[0].atrasado ? (
                <AlertCard>!</AlertCard>
              ) : (
                <AlertCardOff></AlertCardOff>
              )}
              {(() => {
                if (datauser.triglicerideos[0].risco === "0") {
                  return <AlertCorGreen></AlertCorGreen>;
                } else if (datauser.triglicerideos[0].risco === "1") {
                  return <AlertCorYellow></AlertCorYellow>;
                } else {
                  return <AlertCorRed></AlertCorRed>;
                }
              })()}
            </CaixaMonitoramento>
          </a>
        </form>
        <p style={{ textAlign: "center" }}>
          <b>0800 707 5050</b>
        </p>
        <br></br>
        <p style={{ textAlign: "center", marginBottom: 20 }}>
          <a href="/meusdados">
            <b>
              <i class="fas fa-user"></i> &nbsp; Meus Dados
            </b>
          </a>
          &nbsp; &nbsp; &nbsp;|&nbsp; &nbsp; &nbsp;
          <a href="https://wa.me/5521979329317">
            <b>
              <i class="fab fa-whatsapp"></i> &nbsp; Atendimento
            </b>
          </a>
        </p>
      </Content>
    );
  }
}
