/* eslint-disable class-methods-use-this */
import React, { Component } from "react";
import {
  Content,
  TituloTerm,
  InputDadosUsuario,
  InputCheckBoxUsuario,
  ButtonProx,
  Espaco,
  Logo,
  CaixaBranca,
} from "../../styles/global";
import logo from "../../assets/logo.png";

export default class Usuarios extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangeNome = this.handleChangeNome.bind(this);
    this.handleChangeTelefone = this.handleChangeTelefone.bind(this);
    this.handleChangeIdade = this.handleChangeIdade.bind(this);
    this.handleChangeAltura = this.handleChangeAltura.bind(this);
    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    this.handleChangeSexoFemi = this.handleChangeSexoFemi.bind(this);
    this.handleChangeSexoMasc = this.handleChangeSexoMasc.bind(this);

    const datauser = JSON.parse(localStorage.getItem("datauser"));
    this.state = {
      nome: datauser.name,
      telefone: datauser.phone_number.substr(3),
      idade: datauser.record.age
        .split("/")
        .reverse()
        .join("-"),
      altura: datauser.record.height * 100,
      email: datauser.email,
      sexo: datauser.record.gender,
    };

    if (datauser.record.gender === "Masculino") {
      this.state.checked1 = false;
      this.state.checked2 = true;
    } else {
      this.state.checked1 = true;
      this.state.checked2 = false;
    }
  }

  handleChangeSexoMasc() {
    this.setState({
      sexo: "Masculino",
    });
    this.setState({ checked1: false });
    this.setState({ checked2: true });
  }

  handleChangeSexoFemi() {
    this.setState({
      sexo: "Feminino",
    });
    this.setState({ checked1: true });
    this.setState({ checked2: false });
  }

  handleChangeNome(e) {
    this.setState({ nome: e.target.value });
  }

  handleChangeTelefone(e) {
    this.setState({ telefone: e.target.value });
  }
  handleChangeIdade(e) {
    this.setState({ idade: e.target.value });
  }
  handleChangeAltura(e) {
    this.setState({ altura: e.target.value });
  }
  handleChangeEmail(e) {
    this.setState({ email: e.target.value });
  }

  handleSubmit(e) {
    e.preventDefault();
    const datauser = JSON.parse(localStorage.getItem("datauser"));
    var body = new FormData();
    body.append(
      "cpf",
      datauser.cpf
        .replace(".", "")
        .replace(".", "")
        .replace("-", "")
    );
    body.append("name", this.state.nome);
    body.append("phone_number", "55" + this.state.telefone);
    body.append("age", this.state.idade);
    body.append("height", this.state.altura);
    body.append("sexo", this.state.sexo);
    body.append("email", this.state.email);

    console.log(body);

    fetch("http://206.189.175.10/app/index.php/WebApp/register/", {
      method: "POST",
      body: body,
    })
      .then((response) => response.text())
      .then((text) => {
        this.props.history.push("/?cpf=" + datauser.cpf);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  render() {
    return (
      <Content>
        <Logo src={logo} />
        <CaixaBranca>
          <TituloTerm>Seus Dados</TituloTerm>
          <InputDadosUsuario
            type="text"
            name="nome"
            placeholder="Seu Nome"
            onChange={this.handleChangeName}
            value={this.state.nome}
            required
          ></InputDadosUsuario>
          <InputDadosUsuario
            type="text"
            onChange={this.handleChangeTelefone}
            value={this.state.telefone}
            placeholder="Seu Número de Celular"
            required
          ></InputDadosUsuario>
          <InputDadosUsuario
            type="date"
            name="datadenascimento"
            onChange={this.handleChangeIdade}
            value={this.state.idade}
            placeholder="Sua Data de Nascimento"
            required
          ></InputDadosUsuario>
          <InputDadosUsuario
            type="text"
            name="altura"
            onChange={this.handleChangeAltura}
            value={this.state.altura}
            placeholder="Sua Altura em cm"
            required
          ></InputDadosUsuario>
          <InputDadosUsuario
            type="text"
            name="email"
            onChange={this.handleChangeEmail}
            value={this.state.email}
            placeholder="Seu e-mail de cadastro"
            required
          ></InputDadosUsuario>
          <p style={{ textAlign: "center" }}>
            <InputCheckBoxUsuario
              type="checkbox"
              checked={this.state.checked2}
              onClick={this.handleChangeSexoMasc}
            ></InputCheckBoxUsuario>
            Masculino &nbsp; &nbsp; &nbsp;
            <InputCheckBoxUsuario
              type="checkbox"
              checked={this.state.checked1}
              onClick={this.handleChangeSexoFemi}
            ></InputCheckBoxUsuario>
            Feminino
          </p>
        </CaixaBranca>
        <Espaco />
        <ButtonProx onClick={this.handleSubmit.bind(this)} type="submit">
          Salvar
        </ButtonProx>

        <a href="/monitoramento">
          <ButtonProx type="submit">Voltar</ButtonProx>
        </a>

        <p style={{ textAlign: "center" }}>
          <b>0800 707 5050</b>
        </p>
        <br></br>
        <p style={{ textAlign: "center", marginBottom: 20 }}>
          <a href="/monitoramento">
            <b>
              <i class="fas fa-home"></i> &nbsp; Home
            </b>
          </a>
          &nbsp; &nbsp; &nbsp;|&nbsp; &nbsp; &nbsp;
          <a href="https://wa.me/5521979329317">
            <b>
              <i class="fab fa-whatsapp"></i> &nbsp; Atendimento
            </b>
          </a>
        </p>
      </Content>
    );
  }
}
