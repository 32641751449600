import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import Main from "./pages/Main";
import Terms from "./pages/Terms";
import TermsTexto from "./pages/Terms/termos";

import Monitoramento from "./pages/Monitoramento";
import Envio from "./pages/Monitoramento/envio";

import Usuarios from "./pages/Usuario/index";

export default function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/termstexto" exact component={TermsTexto} />
        <Route path="/terms" exact component={Terms} />
        <Route path="/monitoramento" exact component={Monitoramento} />
        <Route path="/envio" exact component={Envio} />
        <Route path="/meusdados" exact component={Usuarios} />
        <Route path="/" exact component={Main} />
      </Switch>
    </BrowserRouter>
  );
}
