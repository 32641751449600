/* eslint-disable class-methods-use-this */
import React, { Component } from "react";
import {
  Content,
  ButtonProx,
  TituloUpdate,
  TextoUpdate,
  Espaco,
  Caixa,
  InputCompleto,
  LabelUpdate,
} from "../../styles/global";

export default class Envio extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleVoltar = this.handleVoltar.bind(this);

    const search = this.props.location.search;
    const params = new URLSearchParams(search);
    const page = params.get("page");

    if (page === "peso") {
      this.state = {
        h1: "Peso",
        p1: "Envie uma foto depois confirme a sua medição.",
        p2: "As medições devem ser feitas até as 12h, após a 1º urina do dia.",
        p3: "Escreva aqui o valor da medição em Kg.",
        page: page,
        botao: "Enviar",
        botaohabilitado: false,
      };
    } else if (page === "circunferencia") {
      this.state = {
        h1: "Circ. Abdominal",
        p1: "Envie uma foto depois confirme a sua medição.",
        p2: "As medições devem ser feitas até as 12h, após a 1º urina do dia.",
        p3: "Escreva aqui o valor da medição em cm.",
        page: page,
        botao: "Enviar",
        botaohabilitado: false,
      };
    } else if (page === "pressao") {
      this.state = {
        h1: "Pressão Arterial",
        p1: "Envie uma foto depois confirme a sua medição.",
        p2: "As medições devem ser feitas até as 12h, após a 1º urina do dia.",
        p3: "Escreva aqui o valor da medição ex: 120/80.",
        page: page,
        botao: "Enviar",
        botaohabilitado: false,
      };
    } else if (page === "glicose") {
      this.state = {
        h1: "Glicose",
        p1: "Envie uma foto depois confirme a sua medição.",
        p2: "As medições devem ser feitas até as 12h, após a 1º urina do dia.",
        p3: "Escreva aqui o valor da medição.",
        page: page,
        botao: "Enviar",
        botaohabilitado: false,
      };
    } else if (page === "triglicerideos") {
      this.state = {
        h1: "Triglicerídeos",
        p1: "Envie uma foto depois confirme a sua medição.",
        p2: "As medições devem ser feitas até as 12h, após a 1º urina do dia.",
        p3: "Escreva aqui o valor da medição.",
        page: page,
        botao: "Enviar",
        botaohabilitado: false,
      };
    } else if (page === "coaching") {
      this.state = {
        h1: "Coaching",
        p1:
          "Seja bem-vindo ao sistema de monitoramento para hábitos saudáveis.\n Nessa página você pode fotografar momentos saudáveis da sua rotina, e comprovar ao seu coach Medex que bateu suas metas semanais.",
        p2: "0800 707 5050",
        p3: "Descreva seu hábito saudável",
        page: page,
        botao: "Enviar",
        botaohabilitado: false,
      };
    }
  }

  handleSubmit(e) {
    this.setState({
      botao: "Enviando ...",
      botaohabilitado: true,
    });
    let cpf = "" + e.target[0].value;
    cpf = cpf
      .replace(".", "")
      .replace(".", "")
      .replace("-", "");
    e.preventDefault();
    const data = new FormData(e.target);
    fetch("http://206.189.175.10/app/index.php/WebApp/upload_values", {
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((result) => {
        alert("Muito Obrigado, recebemos suas informações!");
        this.setState({
          botao: "Enviado",
        });
        this.props.history.push("/?cpf=" + cpf);
      });
  }

  handleVoltar(e) {
    e.preventDefault();
    this.props.history.push("/monitoramento");
  }

  geral() {
    const datauser = JSON.parse(localStorage.getItem("datauser"));
    return (
      <Content>
        <Caixa>
          <form method="POST" onSubmit={this.handleSubmit.bind(this)}>
            <input type="hidden" name="cpfuser" value={datauser.cpf} />
            <input type="hidden" name="categoria" value={this.state.page} />
            <TituloUpdate>{this.state.h1}</TituloUpdate>
            <Espaco></Espaco>
            <TextoUpdate>{this.state.p1}</TextoUpdate>
            <Espaco></Espaco>
            <InputCompleto
              type="file"
              accept="image/*"
              capture="camera"
              name="file"
              required
            ></InputCompleto>
            <Espaco></Espaco>
            <InputCompleto
              type="text"
              id="textoimagem"
              name="textoimagem"
              placeholder={this.state.p3}
              required
            ></InputCompleto>
            <LabelUpdate>{this.state.p3}</LabelUpdate>
            <Espaco></Espaco>
            <TextoUpdate>{this.state.p2}</TextoUpdate>
            <ButtonProx disabled={this.state.botaohabilitado}>
              {this.state.botao}
            </ButtonProx>
            <ButtonProx onClick={this.handleVoltar.bind(this)}>
              Cancelar
            </ButtonProx>
          </form>
        </Caixa>
      </Content>
    );
  }

  render() {
    return this.geral();
  }
}
