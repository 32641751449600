/* eslint-disable class-methods-use-this */
import React, { Component } from "react";
import { Content, ButtonProx, Espaco } from "../../styles/global";

export default class TermsTexto extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(e) {
    e.preventDefault();
    this.props.history.push("/terms");
  }

  render() {
    return (
      <Content>
        <h2>Termos de uso</h2>
        <Espaco />
        <p>
          Os termos deste contrato (“Termos de Uso”) regem o relacionamento
          entre você (“Usuário”) e a Medex [empresa de diagnóstico e tratamento
          de doenças crônicas não transmissíveis] (“Medex”), para os fins de
          utilização do Aplicativo Medex (“Aplicativo” ou o “App”), seus
          produtos, conteúdos e serviços (“Serviços”) que venham a ser
          disponibilizados através do mesmo. Ao utilizar o Aplicativo você
          manifesta estar ciente destes Termos de Uso que regem a sua relação
          com a Medex. Estes Termos devem ser lidos em conjunto com a respectiva
          Política de Privacidade do Aplicativo. Caso você não concorde com
          qualquer cláusula ou condição destes Termos de Uso e da Política de
          Privacidade, solicitamos que não utilize o App. A utilização do
          Aplicativo, parcial ou integralmente, importa na imediata aceitação
          dos seus respectivos Termos de Uso e da Política de Privacidade, na
          forma abaixo. I. Descrição e Finalidade do Aplicativo 1.1. O
          Aplicativo tem por finalidade permitir o desenvolvimento de ações de
          saúde preventiva a partir dos dados coletados dos Usuários e
          visualizados a partir do App. Essa interface possibilita o
          monitoramento remoto do estado de saúde dos Usuários, bem como a
          prevenção e eventualmente o diagnóstico de doenças crônicas não
          transmissíveis (“DCNT”). O Aplicativo pode oferecer funcionalidades
          acessórias, tais como o incentivo à alimentação saudável, à prática de
          atividades físicas e canal para o esclarecimento de dúvidas dos
          Usuários. 1.2. A Medex, ou qualquer responsável pela operação do
          Aplicativo, apenas disponibiliza o App para que esse sirva de
          interface entre os Usuários e seus parceiros, como empregadores,
          médicos e planos de saúde. A Medex não desempenha atividades médicas
          típicas, não oferecendo diagnósticos ou prescrevendo medicamentos ou
          tratamentos. Cabe ao médico que atende ao Usuário realizar tais
          funções. O App apenas fornece uma visualização dos dados médicos nele
          inseridos, permitindo o acompanhamento da evolução do estado de saúde
          do Usuário por si e pelos profissionais devidamente habilitados. 1.3.
          A Medex não é responsável pelas avaliações médicas realizadas pelos
          médicos e demais contratados de seus parceiros comerciais. O Serviço
          prestado pela Medex se restringe à coleta dos dados a seguir
          especificados, à disponibilizar a sua visualização e promover a
          comunicação desses aos parceiros, que serão encarregados, sob sua
          única e exclusiva responsabilidade, de realizar as avaliações médicas
          correspondentes. O acesso e a criação de uma conta no Aplicativo são
          gratuitos e livres de quaisquer custos ou despesas para os Usuários.
          Em adição aos Serviços básicos fornecidos pelo App, eventuais produtos
          e serviços adicionais poderão ser adquiridos pelos Usuários através do
          App, sendo informado previamente ao Usuário as condições da oferta e o
          seu respectivo valor. Toda e qualquer contratação de produtos e
          serviços adicionais será objeto de prévio e expresso consentimento do
          Usuário. Os eventuais produtos e serviços contratados através do App
          ou por quaisquer outros meios entre os Usuários e a Medex serão
          regidos por instrumentos contratuais próprios, permanecendo aplicáveis
          as disposições destes Termos de Uso e da Política de Privacidade. Os
          Usuários e os parceiros comerciais isentam a Medex de qualquer
          responsabilidade com relação à celebração e execução desses contratos.
          II. Cadastro Para acessar as funcionalidades do Aplicativo, o Usuário
          precisará se cadastrar, informando obrigatoriamente alguns dados e
          fornecendo outros em caráter facultativo. O cadastro resultará na
          criação de um login e uma senha de acesso que identificam o Usuário no
          Aplicativo. O Usuário é o único responsável pelo sigilo de sua senha,
          obrigando-se a mantê-la em segredo, devendo prontamente informar, nos
          termos do presente instrumento, qualquer indicação de uso indevido de
          seus login e senha, por qualquer terceiro, em nome do Usuário. Ao se
          cadastrar deverá o Usuário informar apenas dados verdadeiros, sendo de
          sua exclusiva responsabilidade as consequências civis e penais
          advindas da prestação de informação incorreta ou falsa. O Usuário
          declara, para todos os fins de direito e sob as penas da lei, que os
          dados fornecidos em seu cadastro são corretos e verdadeiros,
          autorizando desde já a Medex, ou qualquer responsável pela operação do
          Aplicativo, a adotar os procedimentos legais que entenda cabíveis, a
          seu exclusivo critério, caso se constate que os dados fornecidos são
          incorretos, falsos ou contrários às regras que regem a utilização do
          Aplicativo. A escolha do login será restrita a palavras que ainda não
          tenham sido cadastradas por outros Usuários. É ainda vedada a seleção
          de palavras ofensivas, que violem direitos de propriedade intelectual,
          expressões publicitárias, nomes ou pseudônimos de personalidades
          públicas ou que façam referências às mesmas. Os dados pessoais
          informados no cadastro bem como aqueles coletados a partir do acesso e
          utilização do Aplicativo estarão sujeitos aos termos da Política de
          Privacidade. As informações fornecidas durante o cadastro poderão ser
          retificadas, a qualquer momento, pelo Usuário, por meio do Aplicativo.
          Em caso de dados falsos ou inexatos, a Medex se reserva o direito de
          não concluir o cadastramento ou bloquear as contas já existentes,
          impossibilitando o uso dos serviços pelo Usuário, até que as
          informações sejam corrigidas. III. Condições de Acesso e de Utilização
          do Aplicativo Ao acessar e utilizar o Aplicativo o Usuário expressa de
          imediato a sua aceitação, plena e sem reservas, dos Termos de Uso,
          Política de Privacidade e demais regras que governam a utilização do
          App. Da mesma forma, o Usuário se compromete a observar e respeitar as
          leis em vigor, utilizando o Aplicativo apenas para fins lícitos e que
          respeitem quaisquer direitos de terceiros. Ao acessar e utilizar o
          Aplicativo, o Usuário se obriga a não praticar atos que possam
          danificar, inutilizar, sobrecarregar, deteriorar ou de qualquer forma
          modificar o App e o seu conteúdo conforme são disponibilizados. O
          Usuário se obriga a não praticar quaisquer atos que possam danificar,
          inutilizar, sobrecarregar, deteriorar ou de qualquer forma modificar
          os dispositivos informáticos de outros Usuários, da Medex ou de
          quaisquer terceiros (incluindo hardware e software), bem como
          documentos, arquivos e demais conteúdos armazenados nos referidos
          dispositivos. Ao acessar e utilizar o Aplicativo o Usuário se obriga a
          não praticar atos que possam impedir a normal utilização do App,
          incluindo o seu conteúdo, por parte de demais Usuários e quaisquer
          terceiros. O Usuário se obriga a não utilizar qualquer sistema
          automatizado, inclusive, mas sem se limitar a "robôs", "spiders",
          “scripts” ou "offline readers," que acessem o App e venham a
          utilizá-lo de forma contrária ao previsto nestes Termos de Uso, na
          Política de Privacidade, nas demais regras que governam o uso do
          Aplicativo e na legislação em vigor. É vedado ao Usuário utilizar
          qualquer sistema para o envio de requisições de acesso e utilização do
          App que supere, em um dado período de tempo, o que seria normalmente
          possível responder, levando ao impedimento de acesso, deteriorando ou
          de qualquer forma alterando a experiência de utilização de seus
          conteúdos. É proibida a coleta, o armazenamento e o tratamento, por
          parte do Usuário, de quaisquer informações pessoais dos demais
          Usuários do Aplicativo. É terminantemente vedado ao Usuário, ao
          acessar e utilizar o Aplicativo: (i) exibir, enviar ou de qualquer
          forma divulgar mensagens, arquivos, fotografias ou quaisquer dados ou
          materiais com conteúdo ilegal, violento, difamatório, calunioso,
          sigiloso, abusivo, perigoso, degradante, pornográfico,
          discriminatório, racista ou de qualquer modo ilegais ou atentatórios à
          ordem pública; (ii) praticar ou fomentar a prática de quaisquer atos
          ou atividades ilegais; (iii) exibir, enviar ou de qualquer forma
          divulgar mensagens, arquivos, programas, rotinas ou links cujo
          recebimento possa não ser desejado pelo destinatário, tais como
          correntes, listas de distribuição, “spamming” e similares; (iv)
          exibir, enviar ou de qualquer forma divulgar mensagens, arquivos,
          fotografias ou quaisquer dados ou materiais que violem direitos de
          propriedade intelectual ou qualquer outro direito da administradora do
          Aplicativo ou de quaisquer terceiros. A Medex ou o responsável pela
          operação do Aplicativo poderá eliminar qualquer conteúdo relativo ao
          perfil do Usuário: (i) por determinação legal; (ii) em virtude de
          ordem judicial ou por determinação de autoridade competente; (iii)
          para evitar ou corrigir quaisquer elementos que, a seu exclusivo
          critério, possam trazer ou tenham trazido prejuízos ou qualquer tipo
          de dano à Medex ou a qualquer terceiro; (iv) para identificar,
          corrigir ou evitar quaisquer problemas técnicos na operação do
          Aplicativo; e (v) quando tais conteúdos estiverem em desacordo com o
          previsto no presente instrumento ou na legislação aplicável. A Medex
          ou qualquer responsável pela operação do Aplicativo poderá, a seu
          exclusivo critério, suspender, bloquear ou proibir o acesso de um ou
          mais Usuários, eliminando consequentemente qualquer conteúdo relativo
          ao seus respetivos perfis e contas no App. O Usuário é o único
          responsável por sua utilização do Aplicativo, isentando e obrigando-se
          a indenizar desde já a Medex ou qualquer terceiro por conta de danos
          que possam ser causados. O Usuário se obriga a não transmitir,
          publicar, divulgar ou criar obras derivadas de qualquer material a que
          venha a ter acesso no Aplicativo, incluindo fotografias, textos,
          desenhos, sons, músicas, arquivos, programas, marcas, patentes, sinais
          distintivos, nomes ou qualquer tipo de informação, quando tais
          materiais forem protegidos por direitos de propriedade intelectual
          e/ou quando a divulgação de tais materiais não seja autorizada pelo
          seu autor ou pelo detentor dos seus direitos intelectuais. Essa
          proibição não abrange os usos permitidos pelas limitações e exceções
          aos direitos de propriedade intelectual, no estrito sentido conferido
          pela legislação em vigor. Todas as marcas, nomes comerciais e sinais
          distintivos de qualquer espécie presentes no Aplicativo, incluindo sua
          própria marca e designação, são pertencentes a seus respectivos
          titulares de direito. Para a utilização de quaisquer destas marcas,
          nomes e sinais, é necessário a obtenção de prévia autorização dos seus
          titulares por escrito. IV. Exclusão de Garantias e de Responsabilidade
          A Medex ou qualquer responsável pela operação do Aplicativo envidará
          esforços para que o Aplicativo seja plenamente acessível a todo e
          qualquer tempo. Todavia, não há garantia de que o acesso e a sua
          utilização se deem sem qualquer falha ou de forma ininterrupta. A
          Medex ou qualquer responsável pela operação do Aplicativo não poderá
          ser responsabilizada pelo Usuário ou por qualquer terceiro em função
          do impedimento ou alteração na forma de acesso e utilização do App. A
          MEDEX OU QUALQUER RESPONSÁVEL PELA OPERAÇÃO DO APLICATIVO SE EXIMEM E
          SE EXONERAM, EM TODA A EXTENSÃO PERMITIDA PELO ORDENAMENTO JURÍDICO
          BRASILEIRO E QUALQUER OUTRO APLICÁVEL, DE QUALQUER RESPONSABILIDADE
          PELOS DANOS DE QUALQUER NATUREZA, LUCROS CESSANTES E DANOS EMERGENTES,
          QUE POSSAM SER OCASIONADOS OU DECORRER DA FALTA DE CONTINUIDADE OU
          FUNCIONAMENTO DO APLICATIVO. A Medex ou qualquer responsável pela
          operação do Aplicativo não têm obrigação de monitorar, fiscalizar ou
          controlar o acesso e a utilização que os Usuários fizerem do
          Aplicativo. A Medex ou qualquer responsável pela operação do
          Aplicativo não garantem que os Usuários venham a utilizar o App em
          conformidade com os Termo de Uso, a Política de Privacidade, demais
          regras que governem o acesso e a utilização do Aplicativo, bem como a
          legislação em vigor. A Medex ou qualquer responsável pela operação do
          Aplicativo não têm a obrigação de verificar, e não verificam, a
          identidade dos Usuários, nem a veracidade, vigência e autenticidade
          dos dados que os Usuários informam sobre si mesmos, a outros Usuários
          ou a terceiros. A MEDEX OU QUALQUER RESPONSÁVEL PELA OPERAÇÃO DO
          APLICATIVO SE EXIMEM DE QUALQUER RESPONSABILIDADE PELOS DANOS E
          PREJUÍZOS DIRETOS E/OU INDIRETOS DE QUALQUER NATUREZA QUE POSSAM SER
          DEVIDOS EM VIRTUDE DO ACESSO E DA UTILIZAÇÃO DO APLICATIVO, DO
          CONTEÚDO DISPONIBILIZADO POR SEUS USUÁRIOS, INCLUINDO DANOS
          DECORRENTES DA FALTA DE VERACIDADE, VIGÊNCIA E AUTENTICIDADE DA
          INFORMAÇÃO QUE OS USUÁRIOS FORNECEM ACERCA DE SI PRÓPRIOS, ALÉM
          DAQUELES DECORRENTES DA SIMULAÇÃO POR UM USUÁRIO DA PERSONALIDADE DE
          UM TERCEIRO. TENDO EM VISTA QUE O APLICATIVO TEM POR FINALIDADE A
          VISUALIZAÇÃO DE DADOS DE SAÚDE, A MEDEX, EM NENHUM MOMENTO PODERÁ SER
          RESPONSABILIZADA PELA INSERÇÃO EM SUA BASE DE DADOS DE INFORMAÇÕES
          INCORRETAS – VOLUNTÁRIA OU INVOLUNTARIAMENTE – POR PARTE DOS USUÁRIOS
          DO APP, LEVANDO A DIAGNÓSTICOS OU A TRATAMENTOS INDEVIDOS, ERRÔNEOS OU
          INADEQUADOS. CABE AOS USUÁRIOS TOMAREM AS DEVIDAS CAUTELAS NA INSERÇÃO
          DOS SEUS RESPECTIVOS DADOS NO APLICATIVO. A MEDEX NÃO É RESPONSÁVEL
          PELAS AVALIAÇÕES REALIZADAS PELOS MÉDICOS CONTRATADOS POR PARCEIROS
          QUE TENHAM ACESSO AOS DADOS DO APP. QUALQUER DANO DECORRENTE DE
          AVALIAÇÃO MÉDICA DEVERÁ SER DE RESPONSABILIDADE ÚNICA E EXCLUSIVA DO
          RESPONSÁVEL PELA CONTRATAÇÃO DO PROFISSIONAL, ALÉM DO PRÓPRIO MÉDICO.
          A Medex ou qualquer responsável pela operação do Aplicativo envidarão
          seus melhores esforços para, dentro dos padrões recomendados de
          segurança, garantir que os dados pessoais informados pelos Usuários
          sejam protegidos e mantidos confidenciais. Todavia, a Medex ou
          qualquer responsável pela operação do Aplicativo não pode garantir que
          a proteção dos dados e a segurança do Aplicativo jamais venham a ser
          violados. NESSAS CIRCUNST NCIAS, O USUÁRIO CONSENTE QUE A MEDEX OU
          QUALQUER RESPONSÁVEL PELA OPERAÇÃO DO APLICATIVO NÃO PODERÃO SER
          RESPONSABILIZADOS PELA PRÁTICA NÃO AUTORIZADA DE ATOS DE TERCEIROS QUE
          PROMOVAM INTERCEPTAÇÃO, ELIMINAÇÃO, ALTERAÇÃO, MODIFICAÇÃO OU
          MANIPULAÇÃO DOS CONTEÚDOS DO APLICATIVO E DOS DADOS PESSOAIS E DEMAIS
          INFORMAÇÕES E MATERIAIS DISPONIBILIZADOS PELOS USUÁRIOS. O Usuário é o
          único responsável por qualquer informação que o mesmo disponibilize no
          Aplicativo e pelo uso que faz do mesmo, respondendo integralmente por
          qualquer ofensa a direitos de terceiros que sua utilização possa
          causar. O Usuário assume integralmente a responsabilidade em caso de
          qualquer demanda judicial ou arbitral por parte de terceiros que, de
          qualquer modo, afete a Medex ou qualquer responsável pela operação do
          Aplicativo, seus diretores, empregados e colaboradores, com relação ou
          em decorrência do seu acesso e utilização do Aplicativo em violação
          aos Termos de Uso, Política de Privacidade, regras que governam o
          acesso e utilização do Aplicativo e a legislação em vigor. Caberá ao
          Usuário indenizar regressivamente a Medex ou qualquer responsável pela
          operação do Aplicativo caso as mesmas venham a ser condenadas ao
          pagamento de indenização ou outro pagamento de natureza distinta em
          virtude de atos praticados pelo Usuário quando do seu acesso e
          utilização do Aplicativo. O Aplicativo é disponibilizado para acesso e
          utilização de seus Usuários na forma pela qual o mesmo é exibido, não
          cabendo ao Usuário alegar qualquer prejuízo decorrente da não
          correspondência entre o conteúdo e as funcionalidades disponibilizadas
          e eventuais necessidades distintas por parte do Usuário, tanto no que
          diz respeito à forma como ao conteúdo do Aplicativo. A Medex ou
          qualquer responsável pela operação do Aplicativo não são responsáveis
          pela prestação de quaisquer serviços ou bens, vendidos, anunciados ou
          simplesmente direcionados através do Aplicativo para sites de
          anunciantes, patrocinadores, outros Usuários ou parceiros. Menores de
          idade devem obter a prévia autorização dos pais, tutores ou
          representantes legais antes de acessarem o Aplicativo, os quais serão
          considerados responsáveis por todos os atos praticados pelos menores.
          Os pais, tutores ou representantes legais serão plenamente
          responsáveis também no caso de acesso ao Aplicativo por parte de
          menores sem a devida obtenção de autorização prévia. Cabe a eles a
          integral responsabilidade pela fiscalização das atividades e conduta
          dos respectivos menores sob sua tutela.
        </p>
        <Espaco />
        <p>
          V. Propriedade Intelectual 5.1. O Usuário está ciente de que as
          marcas, nomes, logotipos, bem como todo e qualquer conteúdo, desenho,
          arte ou layout presente no Aplicativo e decorrentes do uso dos
          Serviços, são de propriedade exclusiva da Medex, salvo quando
          expressamente designado de outra forma. 5.2. São vedados quaisquer
          atos ou contribuições tendentes, modificação das características,
          ampliação, alteração ou incorporação em quaisquer outros programas ou
          sistemas do Aplicativo. Toda e qualquer forma de reprodução do
          Aplicativo ou dos Serviços, total ou parcial, permanente, temporária
          ou provisória, de forma gratuita ou onerosa, sob quaisquer
          modalidades, formas ou títulos é expressamente vedada. 5.3. O conteúdo
          disponibilizado no Aplicativo caracteriza somente autorização ao
          Usuário para uso não comercial, pessoal e intransferível, para
          visualizar os conteúdos presentes na Plataforma, não implicando
          qualquer licença, cessão ou transferência de titularidade de direitos
          da Medex ao Usuário relacionados ao conteúdo, marca ou outorga de
          demais direitos. Em caso de violação, a Medex se reserva o direito de
          determinar a imediata remoção do conteúdo, sem prejuízo de outras
          sanções cíveis e penais estabelecidas na legislação pertinente. VI.
          Vigência 6.1. O presente termo é celebrado por prazo indeterminado,
          podendo ser rescindido pela Medex a qualquer momento, a seu exclusivo
          critério, independentemente do pagamento de qualquer compensação ou
          indenização, seja a que título for. 6.2. A Medex ou qualquer
          responsável pela operação do Aplicativo poderá, a qualquer tempo,
          promover modificações nos presentes Termos de Uso, além da Política de
          Privacidade e demais normas que governem o Aplicativo. A continuação
          do uso dos Serviços após a alteração dos Termos e Condições implica
          consentimento e, portanto, vincula o Usuário. VII. Disposições Gerais
          7.1. A versão válida e eficaz dos presentes Termos de Uso é aquela
          disponibilizada atualmente no Aplicativo. Essa versão rege todas as
          relações passadas e presentes entre os Usuários do Aplicativo e entre
          os Usuários e a Medex, respeitados direitos adquiridos, atos jurídicos
          perfeitos e coisas julgadas. O Usuário deverá sempre ler atentamente
          os presentes Termos de Uso e não poderá escusar-se deles alegando
          ignorância sobre seus termos, inclusive quanto a eventuais
          modificações. 7.2. O não exercício pela Medex ou qualquer responsável
          pela operação do Aplicativo de quaisquer direitos ou faculdades que
          lhes sejam conferidos pelos Termos de Uso, pela Política de
          Privacidade, pelas regras que regem o acesso e a utilização do
          Aplicativo ou pela legislação em vigor, bem como a eventual tolerância
          contra infrações aos dispositivos dos instrumentos legais e
          contratuais acima mencionados, não importará em renúncia aos seus
          direitos ou novação, podendo seus direitos ser exercidos a qualquer
          tempo. 7.3. A aceitação destes Termos de Uso bem como da Política de
          Privacidade e das regras que governam o acesso e utilização do
          Aplicativo não importa na criação de qualquer vínculo trabalhista,
          societário, de parceria ou associativo entre a Medex, qualquer
          responsável pela operação do Aplicativo e o Usuário. 7.4. A Política
          de Privacidade e demais regras que governem o acesso e a utilização do
          Aplicativo complementam estes Termos de Uso no que não divergirem do
          presente instrumento. 7.5. O presente termo será regido pelas leis da
          República Federativa do Brasil, independentemente de qualquer
          disposição sobre conflito de leis. 7.6. As partes elegem o Foro
          Central da Comarca do Rio de Janeiro, com preferência a qualquer
          outro, por mais privilegiado que seja, para dirimir quaisquer questões
          oriundas do presente instrumento. A Política de Privacidade (“Política
          de Privacidade”) rege o tratamento dos dados pessoais que você
          (“Usuário”) disponibiliza ao utilizar o Aplicativo Medex (“Aplicativo”
          ou o “App”), seus produtos, conteúdos e serviços (“Serviços”) que
          venham a ser disponibilizados através do mesmo. O Aplicativo é
          desenvolvido e disponibilizado por Medex [empresa de diagnóstico e
          tratamento de doenças crônicas não transmissíveis] (“Medex”). Ao
          utilizar o Aplicativo você manifesta estar ciente desta Política de
          Privacidade que rege a sua relação com a Medex. Ela deve ser lida em
          conjunto com os respectivos Termos de Uso do Aplicativo. Caso você não
          concorde com qualquer cláusula ou condição dos Termos de Uso e da
          Política de Privacidade, solicitamos que não utilize o App. A
          utilização do Aplicativo, parcial ou integralmente, importa na
          imediata aceitação dos seus respectivos Termos de Uso e da Política de
          Privacidade, na forma abaixo. Nós, da Medex, reiteramos nosso
          compromisso com você e nossa preocupação com a sua privacidade e a
          proteção de seus dados pessoais. Em caso de dúvidas sobre esta
          Política de Privacidade, entre em contato conosco através dos canais
          indicados no App ou ao final deste instrumento. I. Coleta de Dados
          Pessoais 1.1. No desempenho de suas atividades, a Medex pode solicitar
          ou obter dados pessoais dos seus Usuários. Essencialmente, os dados
          são enviados pelos Usuários ao realizar exames e/ou ao fornecer
          livremente dados de exames no Aplicativo. 1.2. Tendo em vista a
          finalidade do Aplicativo, dados de saúde como (i) pressão arterial;
          (ii) glicose e triglicerídeos; (iii) peso corporal e; (iv)
          circunferência abdominal, dentre outros, poderão ser exigidos para o
          completo funcionamento do App. O Usuário, desde já, consente com a
          coleta e o tratamento desses dados para as finalidades informadas no
          presente instrumento. 1.3. Caso o Usuário deseje adquirir produtos ou
          serviços pelo App, a Medex realizará a coleta de dados adicionais,
          tais como: CPF e número de cartão de crédito para fins de realização
          de pagamento, pelos Usuários, dos serviços prestados. 1.4. Os dados
          pessoais informados poderão ser armazenados em servidores localizados
          no exterior - preferencialmente nos Estados Unidos tendo em vista os
          serviços de cloud que utilizamos - e somente serão fornecidos a
          terceiros na forma da Lei e/ou mediante ordem judicial. 1.5. Ao
          utilizar o Aplicativo, certas informações sobre o Usuário, como o
          protocolo de Internet (endereço IP), sistema operacional, tempo gasto
          no App, dentre outras informações serão armazenadas pela Medex ou por
          empresa contratada para essa finalidade. Esses registros poderão ser
          utilizados, por exemplo, para análise de tráfego e utilização do App.
          II. Tratamento de Dados Pessoais 2.1. Para a execução dos serviços
          disponibilizados no App, a Medex realizará, sempre respeitando a
          legislação pertinente, a transferência de dados pessoais para os
          parceiros comerciais do Aplicativo, sobretudo para aqueles que serão
          responsáveis por realizar a análise médica dos dados coletados e/ou
          fornecidos pelos Usuários, como operadoras de planos de saúde, por
          exemplo. Ao aceitar a presente Política de Privacidade, o Usuário
          expressamente concorda com essa operação. 2.3. Além dos seus
          parceiros, Medex não fornece os dados dos Usuários do App a terceiros
          fora de sua estrutura organizacional, salvo se necessário ao correto
          funcionamento do App, se houver autorização expressa do Usuário ou
          mediante ordem judicial ou outro procedimento previsto em Lei. 2.4. A
          Medex preza pela privacidade dos Usuários do App e utiliza as
          informações fornecidas por esses com as seguintes finalidades:
          Desenvolvimento de ações de saúde preventiva a partir dos dados
          coletados e visualizados a partir do App. Essa interface possibilita o
          monitoramento remoto do estado de saúde dos Usuários, bem como a
          prevenção e eventualmente o diagnóstico de doenças crônicas não
          transmissíveis (“DCNT”). Para cumprir essa finalidade, os dados
          inseridos no App poderão ser compartilhados com parceiros como
          empregadores, médicos ou empresas de planos de saúde. Envio de
          notificações e/ou email marketing: este canal é utilizado para envio
          de conteúdo segmentado sobre nossos serviços. Realização de transações
          comerciais, no caso de fornecimento de dados de cartão de crédito ou
          débito, com objetivo de realização de compra de produtos ou aquisição
          de serviços. A Medex utiliza estas informações exclusivamente com a
          finalidade de processamento do pagamento em questão. Os dados também
          poderão ser utilizados na gestão e melhoria das funcionalidades do
          Aplicativo, bem como para a customização dos serviços ofertados e a
          realização de estatísticas e estudos. 2.5. Em caso de ordem
          proveniente de autoridade pública requerendo o acesso às informações
          armazenadas, o respectivo Usuário poderá ser notificado para que possa
          requerer as medidas adequadas de defesa. III. Dos sistemas e
          Aplicativos de terceiros 3.1. A MEDEX não se responsabiliza pelo
          tratamento de dados pessoais efetuado por terceiros em razão da
          utilização de sistemas ou de Aplicativos que lhes sejam próprios. Os
          tratamentos realizados por terceiros serão regidos pelas Políticas de
          Privacidade dos respectivos sistemas ou Aplicativos. 3.2. Caberá aos
          Usuários, antes de utilizar o sistema ou Aplicativo de algum dos
          parceiros da Medex, ler atentamente a respectiva Política de
          Privacidade, estando ciente que a Medex não possui qualquer
          responsabilidade ou ingerência sobre os tratamentos de dados pessoais
          eventualmente conduzidos por seus parceiros ou por quaisquer
          terceiros. IV. Da veracidade e atualidade dos dados 4.1. Os Usuários
          garantem que os dados fornecidos são verdadeiros e atuais,
          comprometendo-se a atualizá-los sempre que houver qualquer modificação
          neles. 4.2. Os Usuários poderão acessar, corrigir ou atualizar seus
          dados a qualquer tempo, bem como solicitar a exclusão definitiva de
          seus dados dos servidores da Medex, ficando cientes de que na hipótese
          de remoção de seus dados não mais poderão acessar as funcionalidades e
          serviços disponibilizados. V. Segurança das informações 5.1. A Medex
          tem em vigor medidas de segurança adequadas com vistas à proteção
          contra o acesso não autorizado aos dados armazenados, bem como às
          cópias de segurança realizadas. 5.2. Restringimos o acesso às
          informações às pessoas que necessitem dessas informações para prestar
          o suporte necessário aos Usuários, ou melhorar os nossos serviços.
          Estes indivíduos estão obrigados ao dever de confidencialidade e
          sujeitos a punição disciplinar, incluindo a rescisão de contrato de
          trabalho e ação penal em caso de descumprimento dessa obrigação. VI.
          Canal de Atendimento 6. O Usuário poderá entrar em contato com a Medex
          pelo e-mail contato@medex.net.br. Além disso, o Usuário se obriga a
          manter atualizado em seu cadastro o seu endereço eletrônico, por meio
          do qual se farão as comunicações a ele dirigidas pela Medex.
        </p>
        <Espaco />
        <form onSubmit={this.handleSubmit.bind(this)}>
          <ButtonProx type="submit">Voltar</ButtonProx>
        </form>
      </Content>
    );
  }
}
