import React, { Component } from "react";
import { Content, Caixa, Logo } from "../../styles/global";
import styled from "styled-components";
import logo from "../../assets/logo.png";

export default class Main extends Component {
  constructor(props) {
    super(props);

    const search = this.props.location.search;
    const params = new URLSearchParams(search);
    const cpf = params.get("cpf");
    fetch(
      "http://206.189.175.10/app/index.php/WebApp/getDataUser/?cpfuser=" + cpf
    )
      .then((res) => res.json())
      .then(
        (result) => {
          localStorage.setItem("datauser", JSON.stringify(result));
          const resultado = result;

          fetch(
            "http://206.189.175.10/app/index.php/WebApp/getMonitoringUser/?cpfuser=" +
              cpf
          )
            .then((res) => res.json())
            .then(
              (result) => {
                localStorage.setItem("monitoramento", JSON.stringify(result));
                if (resultado.terms_accepted === "1") {
                  window.location.href = "/monitoramento";
                } else {
                  window.location.href = "/terms";
                }
              },
              (error) => {
                console.log(error);
              }
            );
        },
        (error) => {
          console.log(error);
        }
      );
  }

  handleSubmit(e) {
    e.preventDefault();
  }

  render() {
    return (
      <Content>
        <Caixa>
          <Logo src={logo} />
          Carregando Informações ...
        </Caixa>
      </Content>
    );
  }
}

export const Form = styled.form`
  max-width: 350px;
  margin: 20px auto;
  text-align: center;
`;

export const Center = styled.div`
  max-width: 350px;
  margin: 0 auto;
  text-align: center;
`;

export const Img = styled.img`
  max-width: 90px;
`;
