/* eslint-disable class-methods-use-this */
import React, { Component } from "react";
import {
  Content,
  Caixa,
  TituloTerm,
  TextAceit,
  ButtonProx,
  Espaco,
  Logo,
} from "../../styles/global";
import logo from "../../assets/logo.png";

export default class Terms extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(e) {
    e.preventDefault();
    const datauser = JSON.parse(localStorage.getItem("datauser"));
    fetch(
      "http://206.189.175.10/app/index.php/WebApp/setTermsAccepted/?cpfuser=" +
        datauser.cpf
    )
      .then((res) => res.json())
      .then(
        (result) => {},
        (error) => {
          console.log(error);
        }
      );

    this.props.history.push("/monitoramento");
  }

  render() {
    return (
      <Content>
        <form onSubmit={this.handleSubmit.bind(this)}>
          <Logo src={logo} />
          <Caixa>
            <TituloTerm>Termos de uso</TituloTerm>
            <p>
              Para continuar para a aplicação é necessário que você leia e
              aceite os{" "}
              <a href="/termstexto">
                <b>termos de uso</b>
              </a>
            </p>
            <TextAceit>
              <input type="checkbox" required />
              <label>Li e aceito os termos de uso</label>
            </TextAceit>
          </Caixa>
          <Espaco />
          <Espaco />
          <Espaco />
          <ButtonProx type="submit">Prosseguir</ButtonProx>
        </form>
      </Content>
    );
  }
}
